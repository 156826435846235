.toggle-switch {
  position: relative;
  width: 75px;
  display: inline-block;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  text-align: left;
}
.toggle-switch-checkbox {
  display: none;
}
.toggle-switch-label {
  display: block;
  overflow: hidden;
  cursor: pointer;
  border: 0 solid #bbb;
  border-radius: 20px;
  margin: 0;
}
.toggle-switch-inner {
  display: block;
  width: 200%;
  margin-left: -100%;
  transition: margin 0.3s ease-in 0s;
}
.toggle-switch-inner:before, .toggle-switch-inner:after {
  display: block;
  float: left;
  width: 50%;
  height: 34px;
  padding: 0;
  line-height: 34px;
  font-size: 10px;
  color: white;
  font-weight: bold;
  box-sizing: border-box;
}
.toggle-switch-inner:before {
  content: "ON";
  text-transform: uppercase;
  padding-left: 20px;
  background-color: #1976d2;
  box-shadow: inset 3px 3px 15px #1564b3,
            inset -3px -3px 15px #1d88f2;
  color: #fff;
}
.toggle-switch-disabled {
  background-color: #ddd;
  cursor: not-allowed;
}
.toggle-switch-disabled:before {
  background-color: #ddd;
  cursor: not-allowed;
}
.toggle-switch-inner:after {
  content: "OFF";
  text-transform: uppercase;
  padding-right: 20px;
  background-color: #bbbbbb;
  box-shadow: inset 3px 3px 15px #9f9f9f,
            inset -3px -3px 8px #d7d7d7;
  color: #fff;
  text-align: right;
}
.toggle-switch-switch {
  display: block;
  width: 24px;
  margin: 5px;
  background: #fff;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 40px;
  border: 0 solid #bbb;
  border-radius: 20px;
  transition: all 0.3s ease-in 0s;
}
.toggle-switch-checkbox:checked + .toggle-switch-label .toggle-switch-inner {
  margin-left: 0;
}
.toggle-switch-checkbox:checked + .toggle-switch-label .toggle-switch-switch {
  right: 0px;
}
